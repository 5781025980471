/* TODO: https://github.com/tailwindlabs/tailwindcss/discussions/6974 */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    /*
     * 62.5% of 16px browser font size is 10px
     * example: 1.2rem * 10px = 12px
     * example: if you want 24px, simply use 2.5rem
     */
    font-size: 62.5%; /* 1rem = 10px */
  }

  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  body {
    box-sizing: border-box !important;
    font-size: 1.6rem; /* = 16px */
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    @apply bg-background-secondary text-body-copy;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-body-copy--dark;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ol {
    list-style: auto;
    padding: 0;
    margin: 0;
  }

  img {
    display: block;
    height: auto;
  }

  img.box-shadow,
  figure.box-shadow svg {
    box-shadow: rgba(100, 100, 111, 0.2) 0 0.7rem 2.9rem 0;
    border-radius: 1.1rem;
  }

  a {
    text-decoration: none;
    @apply font-bold text-primary;
  }

  /* LEADING TECHNIQUE https://medium.com/microsoft-design/leading-trim-the-future-of-digital-typesetting-d082d84b202 */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  blockquote,
  pre,
  figure,
  address {
    position: relative;
  }

  h1::before,
  h1::after,
  h2::before,
  h2::after,
  h3::before,
  h3::after,
  h4::before,
  h4::after,
  h5::before,
  h5::after,
  h6::before,
  h6::after,
  p::before,
  p::after,
  /* li::before, */
  /* li::after, */
  blockquote::before,
  blockquote::after,
  pre::before,
  pre::after,
  /* figure::before, */
  /* figure::after, */
  address::before,
  address::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  h1::before,
  h1::after,
  h2::before,
  h2::after,
  h3::before,
  h3::after,
  h4::before,
  h4::after,
  h5::before,
  h5::after,
  h6::before,
  h6::after,
  p::before,
  p::after,
  /* li::before, */
  /* li::after, */
  blockquote::before,
  blockquote::after,
  pre::before,
  pre::after,
  /* figure::before, */
  /* figure::after, */
  address::before,
  address::after {
    margin-bottom: -0.65rem;
    margin-top: -0.65rem;
  }

  /* span {
    display: inline;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  } */

  /* END OF LEADING TECHNIQUE https://medium.com/microsoft-design/leading-trim-the-future-of-digital-typesetting-d082d84b202 */
}

/* ================================================= */
/*                 BORDER RADIUS                     */
/* ================================================= */
table.table-radius {
  border-collapse: separate;
  border-spacing: 0;
}

table.table-radius tr:first-child th:first-child {
  border-top-left-radius: 0.8rem;
}

table.table-radius tr:first-child th:last-child {
  border-top-right-radius: 0.8rem;
}

table.table-radius tr:last-child td:first-child {
  border-bottom-left-radius: 0.8rem;
}

table.table-radius tr:last-child td:last-child {
  border-bottom-right-radius: 0.8rem;
}

/* ================================================= */
/*             BLOG CUSTOM STYLES                    */
/* ================================================= */
#blog-table-container {
  position: relative;
}

#blog-table,
#blog-table th,
#blog-table td {
  font-weight: 400 !important;
  border: 0.1rem solid #efefef !important;
  line-height: 1.4;
  padding: 0.9rem 1.2rem;
}

#blog-table caption {
  text-align: center;
  font-size: 14px;
}

.scroll-indicator {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.scroll-indicator table {
  width: 80rem;
}

.scroll-indicator::-webkit-scrollbar {
  display: none;
}

.gradient-fade,
.gradient-fade-left {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 65px;
  z-index: 10;
  pointer-events: none;
}

.gradient-fade {
  right: -5px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.gradient-fade-left {
  left: -5px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

@media screen and (min-width: 768px) {
  .gradient-fade,
  .gradient-fade-left {
    display: none;
    background: none;
  }
}

/* Fix for 'Descriptive link text helps search engines understand your content' */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal;
}

/* Adjust menu on screens between 1280px and 1330px */

@media screen and (min-width: 1280px) and (max-width: 1330px) {
  .adjust-menu li {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

/* No scroll bars feature */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
